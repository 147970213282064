import React from 'react';
import {
    Grid,
    Box,
    Typography,
    Radio, FormControlLabel,
    FormControl,
    Button,
    Checkbox,
    TextField, Backdrop, CardActions, Card, CardContent,
    CircularProgress,
} from "@mui/material";
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Confetti from 'react-confetti';
import GoalsController, {
    Props,
} from './GoalsController.web';
import { arrow, edit, quotes, three, substanceBottom, arrowright, time, calendar, menuIcon, award_a6, image3 } from './assets';
import DatePickerData from '../../landingpage/src/DatePickerData.web';
import TimePickerData from '../../landingpage/src/TimePickerData.web';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const configJSON = require("./config.js");


export default class SubstanceGoals extends GoalsController {
    constructor(props: Props) {
        super(props);

    }

    renderOptions = (shouldSelectEmptyDate: boolean) => {
        return this.state.options.map((option: any) => {
            const isSelected = this.state.formattedDateInter === option.date || (shouldSelectEmptyDate && option.date === '');
            return (
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'inline-flex',
                        border: isSelected ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                        backgroundColor: isSelected ? '#EFF8FB' : 'transparent',
                        borderRadius: '32px',
                        transition: 'background-color 0.3s, border-color 0.3s',
                        padding: '8px 12px',
                        whiteSpace: 'wrap',
                    }}
                    key={option.value}
                >
                    <FormControlLabel
                        label={<span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>}
                        control={
                            <Radio
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#00A7D1',
                                    },
                                    color: '#E3E3E3',
                                }}
                                onChange={() => this.handleChange(option)}
                                value={option.date}
                                checked={isSelected}
                            />
                        }
                    />
                </Box>
            );
        })
    };


    renderDateField = (doesMatchFirstFive: boolean, shouldSelectEmptyDate: boolean) => {
        const rawDate = this.state.formattedDateInter;
    
        const formattedDate = moment(rawDate, ['DD/MM/YYYY', 'YYYY-MM-DD', 'MM/DD/YYYY'], true).isValid()
            ? moment(rawDate).format('DD/MM/YYYY')
            : '';
    
    
        const shouldShowField = (rawDate !== 'ok' && formattedDate === '' && doesMatchFirstFive) ||
            (this.state.valueOther === 'other' || (shouldSelectEmptyDate && formattedDate === ''));
    
        return shouldShowField ? (
            <div style={{ position: "relative", zIndex: 2, display: 'flex', alignItems: 'center', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                <input
                    type="text"
                    onClick={() => this.openPopUp(1)}
                    data-test-id="otherinput"
                    placeholder="Choose Day"
                    value={formattedDate}
                    style={{
                        width: '100%',
                        fontFamily: 'Chromatica-Regular',
                        fontSize: '16px',
                        padding: '10px 0',
                        border: 'none',
                        outline: 'none',
                        marginRight: '10px',
                        backgroundColor: 'transparent',
                    }}
                    readOnly
                />
                <img
                    data-test-id="otherimage"
                    onClick={() => this.openPopUp(1)}
                    src={calendar.default}
                    alt="Open Calendar"
                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                />
            </div>
        ) : null;
    };


    renderError = () => {
        return (((this.state.formattedDateInter === undefined || this.state.formattedDateInter?.length < 3) && this.state.formattedDateInter !== '') || this.state.formattedDateInter === "Invalid date") && this.state.SubtanceButtonCalled && (
            <p
                style={{
                    color: "#EB3E3E",
                    margin: "0",
                    fontSize: "16px",
                    fontFamily: "Chromatica-Regular",
                    marginTop: "5px",
                    fontWeight: 500,
                    lineHeight: "24px",
                }}
            >
                Date is required!
            </p>
        )
    };

    render() {


        const doesMatchFirstFive = this.state?.options
            .slice(0, 5)
            .some((opt: any) => opt.date === this.state?.formattedDateInter);

        const shouldSelectEmptyDate = !doesMatchFirstFive && this.state?.formattedDateInter != null && this.state?.formattedDateInter != 'no';


        return (
            <>
                <Backdrop open={this.state.isLoading} style={{ color: '#fff', zIndex: 100 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid
                    ref={this.scrollRef}
                    item
                    data-test-id="modal-goals"
                    id="modal-goals"
                    style={{
                        overflowY: 'scroll',
                        height: '100%',
                        backgroundColor: "#F0F0F0",
                        padding: "10px",
                    }}
                >
                    <Grid
                        item
                        style={{
                            height: '88px',
                            backgroundColor: '#0F4257',
                            borderRadius: '16px',
                            position: 'relative'
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                                alignContent: "center",
                                gap: "20px",
                                "@media (max-width: 450px)": {
                                    gap: "10px",
                                }
                            }}>
                            <Box
                                sx={{
                                    fontFamily: "Josefin Sans",
                                    color: "#ffffff",
                                    fontSize: "clamp(25px, 5vw, 40px)",
                                    fontWeight: 300,
                                }} >
                                <Box

                                    sx={{
                                        marginBottom: "4px",
                                        fontWeight: 700,
                                        color: "#EBFF00",
                                        fontSize: "clamp(25px, 5vw, 40px)",
                                        marginLeft: {
                                            xs: '17px',
                                            md: '48px',
                                        },
                                        fontFamily: "Josefin Sans",
                                        lineHeight: "40px",
                                    }}
                                    component="span"
                                >
                                    Well
                                </Box>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                    mt: "3px",
                                    lineHeight: "20px",
                                    color: "#FFFFFF",
                                    fontFamily: "Chromatica-Bold",
                                    fontWeight: 700,
                                    fontSize: "clamp(14px, 2vw, 20px)",
                                    "@media (max-width: 590px)": {
                                        width: "40%",
                                        wordBreak: "break-word",
                                    }
                                }}
                            >
                                Hey, {this.state.userName}</Typography>
                            <Box
                                src={menuIcon.default}
                                onClick={() => { this.SubstanceGoalsTohamburgerMenu() }}
                                component="img"
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    right: "2%",
                                    transform: "translate(-50%, -50%)",
                                    cursor: "pointer",
                                }}
                                alt="menuIcon"
                                data-test-id="subhamburgerMenu"
                            />
                        </Box>
                    </Grid>

                    <Grid
                        item
                        style={{
                            marginTop: '10px',
                            height: 'auto',
                            minHeight: '100vh',
                            width: '100%',
                            marginBottom: '10px',
                            borderRadius: '15px',
                            backgroundColor: 'white',
                            position: 'relative',

                        }}
                    >
                        <Box onClick={() => this.goalsPage()} sx={{ cursor: 'pointer', paddingTop: '30px' }} data-test-id="back-btn-goals">
                            <Box
                                alt="Back Button"
                                sx={{
                                    paddingTop: '6px',
                                    left: { sm: 50, xs: 20 },
                                    position: "absolute",
                                }}
                                component="img"
                                src={arrow.default}
                            />
                            <Typography
                                sx={{
                                    cursor: "pointer",
                                    fontFamily: 'Chromatica-Bold',
                                    left: { sm: 70, xs: 40 },
                                    color: '#252525',
                                    position: "absolute",
                                }}>
                                Back</Typography>
                        </Box>

                        <Box sx={{
                            top: 19,
                            marginLeft: {
                                sm: '50px',
                                xs: '20px'
                            },
                            marginRight: {
                                sm: '0px',
                                xs: '20px'
                            },
                            position: "relative",
                        }}>
                            <Box
                                alt="Img"
                                component="img"
                                src={localStorage.getItem('NewUseFound') == 'Yes' ? three.default : image3.default}
                                sx={{
                                    width: '120px',
                                    height: '180px'
                                }}
                            />
                            <Typography
                                sx={{
                                    marginTop: '112px',
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    fontFamily: 'Josefin Sans',
                                    fontWeight: '900',
                                    fontSize: '32px',
                                    color: '#00A7D1'
                                }}
                            >
                                Substance-Related Goals
                            </Typography>
                            <Typography sx={{
                                color: '#252525',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                marginBottom: '20px',
                                marginTop: {
                                    xs: '20px',
                                    sm: '0px'
                                }
                            }}>
                                This is a place for you to make your substance use goals even more concrete by setting them for a certain day.
                            </Typography>
                            <Typography sx={{
                                paddingBottom: '10px',
                                fontFamily: 'Chromatica-Bold',
                                fontSize: '20px',
                                color: '#252525'
                            }}>You said you wanted to..</Typography>
                            <div
                                data-test-id="divdiv"
                                onClick={this.handleEditDiv.bind(this)}
                                className="popup-container"
                                style={{ position: 'relative', maxWidth: '691px', minWidth: '50px' }}>
                                <TextField
                                    disabled={!this.state.editHandle}
                                    multiline
                                    value={this.state.setValue}
                                    onChange={this.handleInputChange}
                                    inputRef={this.textFieldRef}
                                    defaultValue="Reduce your typical drinking amount from 6 to 3 drinks per week!"
                                    style={{
                                        width: '100%',
                                        marginRight: '10px', wordBreak: 'break-word',
                                        height: '115px',
                                        background: `url(${quotes.default})`,
                                        backgroundSize: 'auto',
                                        backgroundPosition: 'top left',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: '16px',
                                    }}
                                    inputProps={{
                                        style: {
                                            fontSize: '16px',
                                            fontFamily: 'Chromatica-Regular',
                                        }
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '16px',
                                            height: '100%',
                                            '&:hover fieldset': {
                                                borderColor: '#E3E3E3',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#E3E3E3',
                                            },
                                        },
                                        '& .MuiInputBase-input': {
                                            padding: '10px 10px -1px 10px',
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            color: 'black',
                                            WebkitTextFillColor: 'black',
                                        },
                                    }}
                                    rows={3}
                                />
                                <div
                                    data-test-id="editdiv"
                                    onClick={() => { this.handleEdit() }}
                                    style={{
                                        position: 'absolute',
                                        bottom: '2px',
                                        marginBottom: '20px',
                                        right: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: "pointer",
                                        padding: "8px 12px",
                                        borderRadius: "8px",
                                        transition: "all 0.2s ease-in-out",
                                        backgroundColor: "#f9f9f9",
                                    }}
                                    onMouseDown={(e) => (e.currentTarget.style.transform = "scale(0.95)")}
                                    onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}>
                                    <img src={edit.default} alt="Edit Goal" style={{ width: '20px', height: '20px', marginBottom: '2px', marginRight: '5px' }} />
                                    <span style={{

                                        color: '#00A7D1',
                                        fontSize: '16px',
                                        fontFamily: 'Chromatica-Bold',

                                    }}>
                                        Edit Goal
                                    </span>
                                </div>
                            </div>
                            {this.state.setValue.trim()?.length < 1 && this.state.SubtanceButtonCalled && <p style={{
                                color: "#EB3E3E",
                                fontFamily: "Chromatica-Regular",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px", margin: 0, marginTop: '5px'
                            }}>Text field is required!</p>}
                            <div>
                                <Typography sx={{
                                    color: '#252525',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    marginTop: '30px',
                                    marginBottom: '20px'
                                }}>
                                    When do you want to start this goal?
                                </Typography>
                                <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>

                                    {this.state.openCalender && (

                                        <Card style={{
                                            padding: '25px 10px 15px 10px', width: '400px', display: 'flex', flexDirection: 'column',
                                            borderRadius: '16px',
                                            gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                        }}>

                                            <DatePickerData onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined} value={this.state.formattedDateInter} onClose={undefined} />
                                            <CardActions style={{ display: 'flex', gap: '26px' }}>
                                                <Button style={{
                                                    background: '#FFFFFF',
                                                    fontFamily: 'Chromatica-Bold',
                                                    fontSize: '14px',
                                                    textAlign: 'center', textTransform: 'none', width: '90px', height: '43px',
                                                    border: '1px solid #00A7D1',
                                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                    borderRadius: '8px',
                                                }}

                                                    onClick={() => this.closePopUp()}
                                                    data-test-id="closebtn"
                                                >
                                                    Back
                                                </Button>
                                                <Button style={{
                                                    background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                    textAlign: 'center',
                                                    textTransform: 'none',
                                                    width: '90px', height: '40px',
                                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                    borderRadius: '8px',
                                                }}
                                                    data-test-id="confirmBtns"
                                                    onClick={() => this.confirmCal()}
                                                >
                                                    Confirm
                                                </Button>
                                            </CardActions>
                                            {this.state.dateErrorMessage && (
                                                <p
                                                    style={{
                                                        color: "#EB3E3E",
                                                        fontFamily: "Chromatica-Regular",
                                                        fontSize: "16px",
                                                        fontWeight: 500,
                                                        lineHeight: "24px",
                                                        margin: "8px 0 0",
                                                        paddingLeft: "16px",
                                                    }}
                                                >
                                                    {this.state.dateErrorMessage}
                                                </p>
                                            )}
                                        </Card>
                                    )}
                                </Backdrop>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <div style={{
                                        flexWrap: 'wrap',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        gap: '10px'
                                    }}>
                                        {this.renderOptions(shouldSelectEmptyDate)}
                                    </div>
                                </FormControl>
                                {this.renderDateField(doesMatchFirstFive, shouldSelectEmptyDate)}
                                {this.state.formattedDateInter == '' && this.state.SubtanceButtonCalled && (
                                    <p
                                        style={{
                                            color: "#EB3E3E",
                                            margin: "0",
                                            fontSize: "16px",
                                            fontFamily: "Chromatica-Regular",
                                            marginTop: "5px",
                                            fontWeight: 500,
                                            lineHeight: "24px",
                                        }}
                                    >
                                        Select Date, Calendar field is required!
                                    </p>
                                )}
                                {this.renderError()}

                            </div>
                            <Typography sx={{
                                color: '#252525',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                marginBottom: '10px',
                                marginTop: '30px'
                            }}
                            > At what time you need to be reminded?</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', zIndex: '2' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', width: '100%', minWidth: '50px', maxWidth: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                        placeholder="Select Time"
                                        value={this.state.timeSet}
                                        data-test-id="opentime"
                                        onClick={() => { this.openTimer() }}
                                        type="text"
                                        style={{

                                            border: 'none',
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            outline: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                        readOnly
                                    />
                                    <img
                                        data-test-id="timer"
                                        onClick={() => { this.openTimer() }}
                                        src={time.default}
                                        alt="Open Calendar"
                                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}

                                    />
                                </div> {this.state.timeSet && (
                                    <span style={{ marginLeft: '10px', marginTop: '20px', color: '#189718', fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>
                                        {`Great! We'll remind you at ${this.state.timeSet}`}
                                    </span>
                                )}
                            </div>
                            {(this.state.timeSet == undefined || this.state?.timeSet?.length < 1) && this.state.SubtanceButtonCalled && <p style={{
                                color: "#EB3E3E",
                                fontFamily: "Chromatica-Regular",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "24px", margin: 0, marginTop: '5px'
                            }}>Please enter a time for your reminder to continue.</p>}

                            <Backdrop open={this.handlePopupTime(this.state.openTime)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openTime && (
                                    <Card sx={{
                                        gap: '24px',
                                        backgroundColor: '#FFFFFF',
                                        alignItems: 'center',
                                        width: '320',
                                        height: '450px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '28px',
                                        padding: '25px 10px 15px 10px',
                                        "@media (max-width: 414px)": {
                                            width: "280px",
                                        },
                                        "@media (min-width: 601px) and (max-width: 1280px)": {
                                            width: 'auto',
                                        },
                                        "@media (min-width: 800px) and (max-width: 1025px)": {
                                            width: 'auto',
                                        },
                                    }}>
                                        <CardContent style={{
                                            gap: '35px', alignItems: 'center', height: "415px",
                                            display: 'flex', flexDirection: 'column',
                                        }}>
                                            <TimePickerData value={this.state.setTimeValue} onAccept={this.handleUserTime} onChange={this.handleDataChangeTime} navigation={undefined} id={''} onClose={this.handleClose} />
                                        </CardContent>
                                    </Card>
                                )}
                            </Backdrop>
                            <div style={{ display: 'flex', marginTop: '20px' }}>
                                <Checkbox
                                    checked={this.state.setCheckbox || false}
                                    onChange={() => this.handleCheckboxChange()}
                                    sx={{

                                        color: this.state.setCheckbox ? '#00A7D1' : '#E3E3E3',
                                        '&.Mui-checked': {
                                            color: '#00A7D1',
                                        },
                                        marginBottom: '10px',
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '6px',
                                    }}
                                />
                                <Typography sx={{

                                    color: '#252525',
                                    marginLeft: '10px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                }}
                                >  Do you want to make it happen on a daily basis?</Typography>
                            </div>

                            <Button
                                onClick={() => { this.customForm() }}
                                variant="contained"
                                sx={{
                                    padding: "10px",
                                    borderRadius: "16px",
                                    boxShadow: 'none',
                                    background:
                                        "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                                    textTransform: "none",
                                    backgroundColor: '#EFF8FB',
                                    fontSize: "18px",
                                    lineHeight: "20px",
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    cursor: 'pointer',
                                    maxWidth: '348px',
                                    height: '56px',
                                    fontWeight: 400,
                                    fontFamily: 'Chromatica-Bold',
                                    minWidth: '50px',
                                    width: '100%',
                                    zIndex: '2'
                                }}
                                data-test-id="wellspring"
                            >
                                <span style={{ marginRight: '5px' }}>Next</span>
                                <img src={arrowright.default} />
                            </Button>
                            <Box
                                sx={{
                                    height: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    px: 4,
                                }}
                            >

                            </Box>
                        </Box>
                        <Box
                            alt="image" component="img" src={substanceBottom.default}
                            sx={{
                                bottom: 0,
                                right: 0,
                                width: '100%',
                                position: "absolute",
                                height: 'auto',
                                maxWidth: '600px',
                                display: {
                                    xs: 'none',
                                    md: 'block'
                                }
                            }}

                        />
                    </Grid>
                    <Dialog open={this.state.GoalAchievedPopup}
                        PaperProps={{
                            style: { borderRadius: 24, width: 391, overflow: "hidden" },
                        }}
                    >
                        {this.state.GoalAchievedPopup && (
                            <Confetti
                                width={window.innerWidth}
                                height={window.innerHeight}
                                numberOfPieces={200}
                                recycle={false}
                            />
                        )}
                        <DialogTitle>
                            <Box
                                sx={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    textAlign: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '80px',
                                        height: '80px',
                                        boxShadow: '0px 0px 18px 0px #F3A622',
                                        background: '#F3A622',
                                        borderRadius: '50%',
                                        marginBottom: '16px',
                                        alignItems: 'center',
                                        marginTop: "2rem",
                                    }}
                                >
                                    <div
                                        style={{
                                            ...webStyles.submissonBox
                                        }}
                                    >
                                        <img
                                            alt="award_award"
                                            src={award_a6.default}
                                            style={{ textAlign: 'center' }}
                                        />
                                    </div>
                                </Box>

                                <Typography sx={webStyles.Congratulationsstyleinasfer}>
                                    Congratulations!
                                </Typography>
                                <Box sx={{ overflowY: "auto", maxHeight: "120px", margin: "1.5rem 0", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    <Typography sx={webStyles.countingsaferStyle}>You added a goal to {this.state.setValue}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                    <Button
                                        data-test-id="goalModal"
                                        sx={{
                                            padding: "6px 10px",
                                            width: "281px",
                                            height: "44px",
                                            borderRadius: "8px",
                                            border: "none",
                                            background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                                            cursor: "pointer",
                                            textTransform: "none"
                                        }}
                                        onClick={this.handleAddMoreGoal.bind(this)}
                                    >
                                        <Typography sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: 1,
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            color: "white",
                                        }}>
                                            Add Another Goal <AddCircleOutlineIcon style={{ width: "24px", height: "24px" }} />
                                        </Typography>
                                    </Button>
                                    <Button
                                        data-test-id="MoveToDashboard"
                                        sx={{
                                            width: "281px",
                                            height: "44px",
                                            padding: "6px 10px",
                                            borderRadius: "8px",
                                            border: "none",
                                            cursor: "pointer",
                                            textTransform: "none"
                                        }}
                                        onClick={this.MoveToDashboard}
                                    >
                                        <Typography sx={{
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '24px',
                                            color: "#3BA9D5",
                                            textAlign: "center",
                                        }}>
                                            Done
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </DialogTitle>
                    </Dialog>
                </Grid>
            </>
        )
    }
}

const webStyles = {
    closeStyle: {
        right: 8,
        position: 'absolute',
        top: 8,
    },
    submissonBox: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    countingsaferStyle: {
        fontFamily: 'Chromatica-bold',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        color: "#252525",
    },
    Congratulationsstyleinasfer: {
        fontFamily: 'Josefin Sans',
        lineHeight: '40px',
        fontSize: '40px',
        letterSpacing: '-0.04em',
        color: '#00A7D1',
        fontWeight: 700,
    },
};