import React from "react";
// Customizable Area Start
import { Box, Grid, Tabs, Tab, IconButton, CardContent, Card, Paper, Backdrop, CircularProgress, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from "@mui/material/Typography";
import Confetti from 'react-confetti';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
    arrow_Arrow_arrow_down,
    arrow_Arrow_up, award_a,
    award_a1, award_a2,
    award_a6, awrad_Award,
    flower_flower, glass_glass,
    hook_Hook, Menu_M, tobacco, wava_right, checkbox
} from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../../blocks/dashboard/assets/style.css"
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
const WhiteBackgroundContainerSte = styled('div')({
    backgroundColor: 'white',
    padding: "2rem",
    //    height:"auto",
    marginLeft: "0.7rem",
    marginRight: "0.7rem",
    borderRadius: "16px",
    '@media (max-width: 486px)': { // Mobile devices
        padding: "1rem !important"
    },
});



// Customizable Area End
import SaferuseStrategiesController, {
    Props,
    configJSON
} from "./SaferuseStrategiesController.web";
import DeleteModal from "./DeleteModal.web";




export default class SaferuseStrategies extends SaferuseStrategiesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    formatDateRange(card: any) {
        const createdAtDate = new Date(card.created_at);

        const dayOfWeek = createdAtDate.getDay();

        const startDate = new Date(createdAtDate);
        const daysToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
        startDate.setDate(createdAtDate.getDate() - daysToMonday);

        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
        const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getDate()}`;

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];

    icons = [
        <img src={award_a.default} alt="awrad_award" />,
        <img src={award_a1.default} alt="awrad_award" />,
        <img src={award_a2.default} alt="awrad_award" />,
    ];
    seprateFuntion = (card: any,) => {
        return (
            <Box style={{
                width: '38px',
                minWidth: '38px',
                maxWidth: '38px', height: '38px',
                minHeight: '38px',
                maxHeight: '38px', display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <span style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    {card.icon_data?.url ? (
                        <Box
                            component="img"
                            src={glass_glass.default}
                            alt="Dynamic"
                            sx={{
                                width: "100%",
                                height: '100%',
                                '@media (max-width: 900px)': {
                                    width: '100%',
                                    height: '100%',
                                },
                                '@media (max-width: 600px)': {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        />
                    ) : (
                        <>
                            {this.state.selectsafeValues === "Alcohol" && (
                                <img src={glass_glass.default} alt="glass" width={32} height={32} />
                            )}
                            {this.state.selectsafeValues === "Cannabis" && (
                                <img src={flower_flower.default} alt="flower" width={32} height={32} />
                            )}
                            {this.state.selectsafeValues === "Tobacco or Nicotine" && (
                                <img src={tobacco.default} alt="tobacco" width={32} height={32} />
                            )}
                            {this.state.selectsafeValues === "Uppers" && (
                                <img src={arrow_Arrow_up.default} alt="Uppers" width={32} height={32} />
                            )}
                            {this.state.selectsafeValues === "Downers" && (
                                <img src={arrow_Arrow_arrow_down.default} alt="Downers" width={32} height={32} />
                            )}
                        </>
                    )}
                </span>
            </Box>
        )
    }

    cardShowdata = (data: any) => {

        return (
            <>
                <Grid container spacing={2} >
                    {this.state.saferuserData.map((card: any, index: any) => {
                        const achievedStat = this.state.showAchievedStrategy === card.id || card.status === "achieved";
                        return (
                            <Grid
                                item

                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                            >
                                <Card variant="outlined" sx={{ position: 'relative', overflow: 'visible', borderRadius: '8px', maxWidth: '100%', width: '310px', minHeight: `${this.state.tabValue ? '157px' : '108px'}`, "@media (max-width: 600px)": { height: "auto" } }} className="cardsizemagane">
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem", minHeight: `${this.state.selectsafeValues === 'Tobacco or Nicotine' ? '96px' : '48px'}`, "@media (max-width: 600px)": { minHeight: "auto" } }}>
                                                {this.seprateFuntion(card)}
                                                <Box sx={{ width: '70%' }}>
                                                    <span style={webStyles.drinksStyletitle}>{card.title}</span>
                                                </Box>
                                            </Box>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            style={{
                                                marginTop: 8,
                                                color: '#00A7D1',
                                                fontFamily: 'Chromatica-Bold',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                                lineHeight: '20px',
                                            }}
                                        >
                                            {this.formatDateRange(card)}
                                        </Typography>
                                        <Box>
                                            {
                                                this.state.tabValue === 1 &&
                                                <>
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <Box style={{ marginTop: '1rem' }}>
                                                            <Box component="button" className={
                                                                achievedStat ? 'activebtn' : 'defaultbtn'
                                                            }
                                                                sx={{ width: { md: "278px !important", sm: "240px !important" } }}
                                                                onClick={achievedStat ? undefined : this.handleToggle.bind(this, card)}
                                                            >
                                                                <Typography sx={webStyles.achievegoalstext}>
                                                                    {achievedStat ? <Box sx={{
                                                                        display: "flex", alignItems: "center", gap: 1, justifyContent: "center"
                                                                    }}><img src={checkbox.default} width={16} height="16px" style={{
                                                                        borderRadius: "5px"
                                                                    }} /> Strategy Achieved</Box> : 'Achieved Strategy'}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            }
                                            <Box>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                    {card.status !== 'achieved' && (
                                        <div style={{ overflow: 'visible' }}>
                                            <IconButton
                                                style={{ position: 'absolute', right: 8, top: 16, cursor: 'pointer' }}
                                                onClick={this.handleIconClicksafer.bind(this, card.id)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            {this.state.isPopupOpensafer === card.id && (
                                                <Paper
                                                    className="popup-container"
                                                    style={{
                                                        width: "130px",
                                                        zIndex: 1000,
                                                        top: '50px',
                                                        right: '10px',
                                                        borderRadius: '8px',
                                                        padding: '1rem',
                                                        position: 'absolute',
                                                    }}
                                                    elevation={3}
                                                >
                                                    <Typography
                                                        style={{ padding: '4px 0', cursor: 'pointer', ...webStyles.DeleteStylesafer }}
                                                        onClick={() => this.handleEditSaferUserData(card?.id)}
                                                    >
                                                        Edit
                                                    </Typography>
                                                    <Typography id="delete-modal-btn" style={{ cursor: 'pointer', padding: '4px 0', ...webStyles.DeleteStylesafer }}
                                                        onClick={() => this.openDelteModal({ cardId: card?.id })}
                                                    >
                                                        Delete
                                                    </Typography>
                                                </Paper>
                                            )}
                                        </div>
                                    )}

                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </>
        )
    }

    renderData = (data: any) => {
        return (
            <>
                {data ?
                    "" :
                    this.cardShowdata(this.state.noData)
                }
            </>
        );
    };

    subTabsinalcohoandall = () => {
        const checkCategoryValue = (category: string) => {
            return this.state.categories.includes(category) ? "" : "none !important";
        };
        return (
            <>
                <Box sx={{ borderColor: 'divider', backgroundColor: this.state.noData ? "transparent" : "#F7F7F7" }}>
                    <Grid container sx={{ overflow: "hidden", display: "block" }}>
                        <Grid item md={12}>
                            <Tabs
                                value={this.state.selectsafeValues}
                                onChange={this.handleStretigies}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="substance tabs"
                                TabIndicatorProps={{
                                    style: { display: 'none' }
                                }}
                                sx={{
                                    '@media (max-width: 486px)': {
                                        overflowX: 'scroll',
                                    },
                                    '@media (max-width: 820px)': {
                                        overflowX: 'scroll',
                                    },
                                    '& .MuiTabs-scrollButtons': {
                                        display: 'none',
                                    },
                                }}
                            >
                                {this.state.noData ? "" :
                                    <Tab
                                        value="Alcohol"
                                        icon={<img src={awrad_Award.default} style={{ width: "16px", height: '28px' }} />}
                                        label="Alcohol"
                                        iconPosition="start"
                                        sx={{
                                            ...webStyles.tabal,
                                            ...(this.state.selectsafeValues === "Alcohol" && {
                                                backgroundColor: 'white',
                                                borderTopColor: '#E5E5E5',
                                                borderRightColor: '#E5E5E5',
                                            }),
                                            display: checkCategoryValue("Alcohol"),
                                            transition: 'all 0.3s ease',
                                            gap: "0.4rem",
                                            padding: '8px 12px',
                                            '&.Mui-selected': {
                                                color: "#252525",
                                                fontFamily: 'Chromatica-Bold',
                                                fontSize: "16px",
                                                fontWeight: 700,
                                                lineHeight: "20px",
                                                borderBottom: "none",
                                            }
                                        }}
                                    />

                                }
                                {this.state.noData ? "" : <Tab
                                    value="Cannabis"
                                    icon={<img src={flower_flower.default} style={{ width: "37px", height: '37px', marginTop: "-6px" }} />}
                                    label="Cannabis"
                                    iconPosition="start"
                                    sx={{
                                        ...webStyles.tabca,
                                        ...(this.state.selectsafeValues === "Cannabis" && {
                                            backgroundColor: 'white',
                                            borderTopColor: '#E5E5E5',
                                            borderRightColor: '#E5E5E5',
                                        }),
                                        display: checkCategoryValue("Cannabis"),
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                            borderBottom: "none",
                                        }
                                    }}
                                />}
                                {this.state.noData ? "" :
                                    <Tab
                                        value="Tobacco or Nicotine"
                                        icon={<img src={hook_Hook.default} style={{ width: "35px", height: '35px', marginTop: "-6px" }} />}
                                        label="Tobacco or Nicotine"
                                        iconPosition="start"
                                        sx={{
                                            ...webStyles.tabto,
                                            ...(this.state.selectsafeValues === "Tobacco or Nicotine" && {
                                                backgroundColor: 'white',
                                                borderTopColor: '#E5E5E5',
                                                borderRightColor: '#E5E5E5',
                                            }),
                                            display: checkCategoryValue("Tobacco or Nicotine"),
                                            transition: 'all 0.3s ease',
                                            gap: "0.4rem",
                                            '&.Mui-selected': {
                                                color: "#252525",
                                                fontFamily: 'Chromatica-Bold',
                                                borderBottom: "none",
                                                fontSize: "16px",
                                                fontWeight: 700,
                                                lineHeight: "20px",
                                            }
                                        }}
                                    />}
                                {this.state.noData ? "" : <Tab
                                    value="Uppers"
                                    icon={<img src={arrow_Arrow_up.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                                    label="Uppers"
                                    iconPosition="start"
                                    sx={{
                                        ...webStyles.tabup,
                                        ...(this.state.selectsafeValues === "Uppers" && {
                                            backgroundColor: 'white',
                                            borderTopColor: '#E5E5E5',
                                            borderRightColor: '#E5E5E5',
                                        }),
                                        display: checkCategoryValue("Uppers"),
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            borderBottom: "none",
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />}
                                {this.state.noData ? "" : <Tab
                                    value="Downers"
                                    icon={<img src={arrow_Arrow_arrow_down.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                                    label="Downers"
                                    iconPosition="start"
                                    sx={{
                                        ...webStyles.tabdo,
                                        ...(this.state.selectsafeValues === "Downers" && {
                                            backgroundColor: 'white',
                                            borderTopColor: '#E5E5E5',
                                            borderRightColor: '#E5E5E5',
                                        }),
                                        display: checkCategoryValue("Downers"),
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            borderBottom: "none",
                                            lineHeight: "20px",
                                        }
                                    }}
                                />}

                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ marginTop: '1rem', minHeight: { md: '220px', xs: "40vh", sm: "55vh" } }}>
                    {this.state.selectsafeValues === "Alcohol" && this.renderData(this.state.noData)}
                    {this.state.selectsafeValues === "Cannabis" && this.renderData(this.state.noData)}
                    {this.state.selectsafeValues === "Tobacco or Nicotine" && this.renderData(this.state.noData)}
                    {this.state.selectsafeValues === "Uppers" && this.renderData(this.state.noData)}
                    {this.state.selectsafeValues === "Downers" && this.renderData(this.state.noData)}
                    {this.state.selectsafeValues === "Others" && this.renderData(this.state.noData)}
                </Box>
            </>
        )
    }
    HeaderFunctionSte = () => {
        return (
            <>
                <Grid
                    item
                    sx={{ ...webStyles.wholeGridStylestra }}
                >
                    <Box sx={{ ...webStyles.wholedivStylestra }}>
                        <Box sx={{
                            ...webStyles.gapMstra, "@media (max-width: 450px)": {
                                gap: "10px",
                            }
                        }} >
                            <Box
                                sx={{ ...webStyles.parentWellstra }}
                                className="fontsizepresent"
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        ...webStyles.well__wellStylesstra,
                                        marginLeft: {
                                            xs: "17px !important",
                                            md: "48px !important",
                                        },
                                    }}
                                    className="well__wellStylesgoals"
                                >
                                    Well
                                </Box>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                    ...webStyles.homeStylemenustra,
                                    "@media (max-width: 590px)": {
                                        width: "40%",
                                        wordBreak: "break-word",
                                    }
                                }}
                            >
                                Hey, {this.state.userName}</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            data-test-id="menuIcon"
                            onClick={() => this.hamburgerMenusafestr()}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "50px",
                                "@media (max-width: 530px)": {
                                    marginRight: "20px !important"
                                },
                                "@media (min-width: 531px) and (max-width:1200px)": {
                                    marginRight: "30px !important"
                                }
                            }}
                            alt="icon"
                        />
                    </Box>
                </Grid>
            </>
        )
    }
    tabThisWeekfunctionbox = () => {
        return (
            <>
                <Box>
                    <Box style={{ marginTop: "1rem" }}>
                        <Typography sx={webStyles.Statisticsstyles}>
                            Statistics
                        </Typography>
                    </Box>
                    <Box style={{ marginTop: "1rem" }}>
                        <Typography sx={webStyles.youaidYourStyleboxs}>{this.state.tabValue === 0 ? 'This week, you said your Strategies are to' : 'Last week, you said your Strategies were to'}</Typography>
                    </Box>
                    {this.state.noData ?
                        this.state.tabValue === 0 ? <div style={{ textAlign: "center", marginTop: "30px" }}>
                            <p
                                style={{
                                    minHeight: "95px",
                                    fontFamily: 'Josefin Sans',
                                    fontSize: '30px',
                                    fontWeight: 500,
                                    color: "#00A7D1",
                                    textAlign: "center",
                                    margin: "0 auto",
                                    maxWidth: "80%",
                                }}
                            >
                                Looking for last week’s strategies? Check the <b>Past Strategies</b> tab. Ready for new ones? Click <b>Add More Safer-use Strategies</b> to get started!
                            </p>
                        </div>
                            :
                            <div style={{ textAlign: "center", marginTop: "30px" }}>
                                <p
                                    style={{
                                        minHeight: "95px",
                                        fontFamily: 'Josefin Sans',
                                        fontSize: '30px',
                                        fontWeight: 500,
                                        color: "#00A7D1",
                                        textAlign: "center",
                                        margin: "0 auto",
                                        maxWidth: "80%",
                                    }}
                                >
                                    No strategies from last week? No problem! Click <b>Add More Strategies</b> to set new ones for this week—they’ll be here to track next Monday!
                                </p>
                            </div>
                        : ""}
                </Box >
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <div>
                <Backdrop open={this.state.isLoading} style={{ color: '#fff', zIndex: 10 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box ref={this.scrollRef}>
                    <Grid item
                        style={{
                            background: '#f0f0f0',
                            width: '100%',
                            padding: "10px",
                        }} className="managenabar">{this.HeaderFunctionSte()}</Grid>

                    <WhiteBackgroundContainerSte sx={{
                        backgroundColor: 'white', height: 'auto', minHeight: 'fit-content', "@media (max-width: 1025px) and (min-width: 800px)": {
                            minHeight: "86vh",
                        }
                    }}>
                        <Box
                            component="img"
                            alt="Logo"
                            src={wava_right.default}
                            sx={{
                                position: "absolute",
                                top: "110px",
                                right: "0px",
                                zIndex: 1,
                            }}
                        />
                        <Box sx={{ position: "relative", zIndex: 2, width: "fit-content", display: "flex", justifyContent: 'flex-start', alignItems: "center", gap: '0.5rem', padding: "1rem 0", }}
                            onClick={() => { this.goToBackDashboards() }}
                        >
                            <KeyboardBackspaceIcon sx={{ cursor: "pointer" }} />
                            <Typography style={webStyles.backStylebox}>Back</Typography>
                        </Box>
                        <Box style={{ marginTop: "0.9rem" }}>
                            <Box sx={{ ...webStyles.addGoalsStylebox, position: "relative", zIndex: 2 }} className="flexcolumn">
                                <Box className="centertoleft">
                                    <Typography sx={webStyles.goalsStyleleftbox} className="setFont">Safer-use Strategies</Typography>
                                </Box>

                                <Box className="buttinmaringset">
                                    <Box
                                        component="button"
                                        className="buttonStylesss"
                                        sx={{ minWidth: { md: '310px !important', xs: 'auto !important' } }}
                                        onClick={this.goTosubandcate.bind(this)}
                                    >
                                        <div style={webStyles.iconflexbox}>
                                            <Typography sx={webStyles.addGoalsStyleboxAddbox} className="setfontsize">Add More Safer-use Strategies</Typography>
                                            <AddCircleOutlineIcon style={{ width: "20px", height: "20px", color: "white", }} />
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Typography style={{
                            margin: " 15px 0px 15px",
                            fontSize: '16px',
                            letterSpacing: '0.0075em',
                            color: 'rgb(37, 37, 37)',
                            fontWeight: 400,
                            lineHeight: '25px',
                            textAlign: 'left',
                            fontFamily: 'Chromatica-Regular',
                            zIndex: 2,
                            position: 'relative'
                        }}>Tap <b>This week</b> to view your current strategies. To make changes, tap the three dots in the corner.</Typography>

                        <Typography style={{
                            margin: " 0px 0px 10px",
                            fontSize: '16px',
                            letterSpacing: '0.0075em',
                            color: 'rgb(37, 37, 37)',
                            fontWeight: 400,
                            lineHeight: '25px',
                            textAlign: 'left',
                            fontFamily: 'Chromatica-Regular',
                            zIndex: 2, 
                            position: 'relative'
                        }}>Every Monday, your current strategies move to <b>Past Strategies</b>, and you’ll get a notification to check in.
                            After reviewing your progress, tap <b>Add More Safer-Use Strategies</b> to set new ones for this week! </Typography>

                        <Box className="marginset">
                            <Box sx={{ width: '100%', position: "relative", zIndex: 2 }}>

                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={this.handleChangeSte}
                                    aria-label="basic tabs example"
                                    sx={{ borderBottom: 2, borderColor: 'divider', color: "#E3E3E3" }}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: '#00A7D1',
                                        },
                                    }}
                                >
                                    <Tab
                                        label="This week"
                                        sx={{
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            color: "#A0A0A0",
                                            textTransform: "none",
                                            '&.Mui-selected': {
                                                color: "#000",
                                                fontFamily: 'Chromatica-Bold',
                                                fontSize: "16px",
                                                fontWeight: 700,
                                                lineHeight: "20px",
                                            }
                                        }}
                                    />
                                    <Tab
                                        label="Past Strategies"
                                        sx={{
                                            fontFamily: 'Chromatica-Bold',
                                            fontSize: "16px",
                                            fontWeight: 400,
                                            lineHeight: "20px",
                                            textTransform: "none",
                                            color: "#A0A0A0",
                                            '&.Mui-selected': {
                                                color: "#000",
                                                fontFamily: 'Chromatica-Bold',
                                                fontSize: "16px",
                                                fontWeight: 700,
                                                lineHeight: "20px",
                                            }
                                        }}
                                    />
                                </Tabs>
                                <Box>
                                    {this.tabThisWeekfunctionbox()}
                                </Box>
                                <Box style={{ marginTop: '1rem', height: 'auto', minHeight: '62vh' }}>
                                    <this.subTabsinalcohoandall />
                                </Box>
                            </Box>
                        </Box>
                    </WhiteBackgroundContainerSte>
                </Box>
                <Dialog onClose={this.handleClosesaferToggle} open={this.state.openCongratessafer}
                    PaperProps={{
                        style: { borderRadius: 24, width: 391, overflow: "hidden" },
                    }}
                >
                    {this.state.openCongratessafer && (
                        <Confetti
                            width={window.innerWidth}
                            height={window.innerHeight}
                            numberOfPieces={200}
                            recycle={false}
                        />
                    )}
                    <DialogTitle>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80px',
                                    height: '80px',
                                    boxShadow: '0px 0px 18px 0px #F3A622',
                                    background: '#F3A622',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '16px',
                                    marginTop: "2rem",
                                }}
                            >
                                <div
                                    style={{
                                        ...webStyles.submissonBox

                                    }}
                                >
                                    <img
                                        src={award_a6.default}
                                        alt="award_award"
                                        style={{ textAlign: 'center' }}
                                    />
                                </div>
                            </Box>

                            <Typography sx={webStyles.Congratulationsstyleinasfer}>
                                Congratulations!
                            </Typography>
                            <Typography sx={webStyles.countingsaferStyle}>You achieved your strategy to {this.state.achievedText}.</Typography>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                                {/* <Button
                                    data-test-id="goalModal"
                                    sx={{
                                        padding: "6px 10px",
                                        width: "281px",
                                        height: "44px",
                                        borderRadius: "8px",
                                        border: "none",
                                        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                                        cursor: "pointer",
                                        textTransform: "none"
                                    }}
                                    onClick={this.goTosubandcate.bind(this)}
                                >
                                    <Typography sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                        fontFamily: 'Chromatica-Bold',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        color: "white",
                                    }}>
                                        Add Another Strategy <AddCircleOutlineIcon style={{ width: "24px", height: "24px" }} />
                                    </Typography>
                                </Button> */}
                                <Button
                                    data-test-id="MoveToDashboard"
                                    sx={{
                                        width: "281px",
                                        height: "44px",
                                        padding: "6px 10px",
                                        borderRadius: "8px",
                                        border: "none",
                                        cursor: "pointer",
                                        textTransform: "none",
                                        marginTop: '-30px'
                                    }}
                                    onClick={this.handleClosesaferToggle}
                                >
                                    <Typography sx={{
                                        fontFamily: 'Chromatica-Bold',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        color: "#3BA9D5",
                                        textAlign: "center",
                                    }}>
                                        Done
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>

                        <IconButton
                            aria-label="close"
                            sx={{
                                ...webStyles.closeStyle, color: "#000000"
                            }}
                            onClick={this.handleClosesaferToggle}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                </Dialog>
                <DeleteModal
                    isOpen={this.state.deleteModal}
                    handleClose={this.closeDeleteModal}
                    webStyles={webStyles}
                    onDelete={this.onStatergyDelete}
                    goalText={"strategy"}
                />
            </div>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    closeStyle: {
        position: 'absolute',
        right: 8,
        top: 8,
    },
    submissonBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    tabal: {
        display: 'flex',
        alignItems: "center",
        flexDirection: 'row',
        fontFamily: 'Chromatica-Regular',
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '28px',
        color: "#252525",
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 0px",
        borderBottom: '1px solid #E5E5E5',
    },

    tabdo: {
        display: 'flex',
        flexDirection: 'row',
        color: "#252525",
        alignItems: "center",
        fontFamily: 'Chromatica-Regular',
        borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 0px",
    },
    tabup: {
        display: 'flex',
        flexDirection: 'row',
        color: "#252525",
        alignItems: "center",
        justifyContent: 'flex-start',
        fontFamily: 'Chromatica-Regular',
        borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 0px",
    },
    tabto: {
        display: 'flex',
        color: "#252525",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: "center",
        fontFamily: 'Chromatica-Regular',
        borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 0px",
    },
    tabca: {
        display: 'flex',
        color: "#252525",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'flex-start',
        fontFamily: 'Chromatica-Regular',
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderBottom: "1px solid #E5E5E5",
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 0px",
    },





    AchievedStyleget: {
        padding: "0.4rem",
        width: "278px",
        borderRadius: "8px",
        border: "none",
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
    },
    StylegetAchieved: {
        padding: "0.4rem",
        width: "191px",
        borderRadius: "8px",
        border: "none",
        background: "linear-gradient(99.09deg, #004463 2.64%, #00A7D1 100%)"
    },
    drinksStyletitle: {
        fontFamily: 'Chromatica-bold',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '24px'
    },
    topparentGridsBox: {
        padding: '10px',
        backgroundColor: '#f0f0f0',
    },
    parentBoxes: {
        right: 0,
        marginRight: '30px',
        position: "absolute",
        top: 0,
    },
    heyToStyle: {
        position: "absolute",
        top: "20px",
        left: "260px",
        fontSize: "17px",
        width: '180px',
        color: "white",
        fontFamily: 'Chromatica-Bold',
    },
    SpringStyles: {
        fontSize: "40px", fontWeight: "normal", marginLeft: "5px"
    },
    wellStyles: {
        fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px"
    },
    countingsaferStyle: {
        fontFamily: 'Chromatica-bold',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        color: "#252525",
        marginBottom: "3rem",
        marginTop: "1.5rem",
        maxHeight: "190px",
        overflowY: "auto",
    },
    Congratulationsstyleinasfer: {
        fontFamily: 'Josefin Sans',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        color: '#00A7D1',
    },

    childGrids: {
        color: "#ffffff",
        fontFamily: 'Josefin Sans',
        position: "absolute",
        top: "36px",
        left: '48px',
        width: '95%'
    },

    parentGrids: {
        height: '88px',
        backgroundColor: '#0F4257',
        margin: '10px',
        borderRadius: '16px',
        width: '99%',
    },
    youaidYourStyleboxs: {
        fontFamily: 'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color: "#252525"
    },
    Statisticsstyles: {
        fontFamily: 'Chromatica-Regular',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        color: "#000000"
    },
    iconflexbox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: '0.5rem'
    },

    buttonStyle: {
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",

        paddingLeft: "22px",
        width: "320px",
        height: "44px",
        padding: "0.5rem",
        borderRadius: "8px 0 0 0",
        opacity: 0
    },
    integrationBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: '0.4rem'
    },
    goalsStyleleftbox: {
        fontFamily: "Josefin Sans",
        fontSize: "32px",
        fontWeight: 600,
        lineHeight: "32px",
        color: "#00A7D1"
    },
    AchievedStyle: {
        padding: "0.4rem",
        width: "131px",
        borderRadius: "8px",
        border: "none",
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
    },
    addGoalsStyleboxAddbox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: '#ffffff',
    },
    stilStyle: {
        fontFamily: 'Chromatica-Bold',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: "#1D5E79",

    },
    achievegoalstext: {
        fontFamily: 'Chromatica-Bold',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color: "white",
        textAlign: 'center',
        cursor: "pointer",
    },

    addGoalsStylebox: {
        display: "flex", justifyContent: 'space-between', alignItems: "center", flexWrap: "wrap"


    },
    DeleteStylesafer: {
        fontFamily: 'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#252525'
    },
    backStylebox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        cursor: "pointer",
    },
    flexpropertybox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    testStyleforHeyTombox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#ffffff",
        marginLeft: "1.3rem"
    },

    threebythreeStylebox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        color: "#000000",
        textTransform: 'lowercase',

    },
    flexBoxparent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    labelstylestepperbox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '20px',
        color: "#252525"

    },
    goalStylebox: {
        fontFamily: 'Chromatica-Bold',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',

    },
    YourStylebox: {
        fontFamily: 'Josefin Sans',
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '32px',
        color: "#00A7D1"

    },

    wholedivStylestra: {
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
        height: "100%",
    },
    wholeGridStylestra: {
        height: '88px',
        backgroundColor: '#0F4257',
        borderRadius: '16px',
        position: 'relative',
    },
    well__wellStylesstra: {
        fontFamily: "Josefin Sans",
        lineHeight: "40px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#EBFF00",
        fontSize: "clamp(25px, 5vw, 40px) !important",
    },
    homeStylemenustra: {
        mt: "3px",
        fontSize: "clamp(14px, 2vw, 20px)",
        lineHeight: "20px",
        color: "#FFFFFF",
        fontFamily: "Chromatica-Bold",
        fontWeight: 700,
    },
    gapMstra: {
        gap: "20px",
        display: "flex",
        alignItems: "center",
    },
    parentWellstra: {
        fontFamily: "Josefin Sans",
        color: "#ffffff",
        fontSize: "clamp(25px, 5vw, 40px) !important",
        fontWeight: 300,
    },
    dialogStyleparent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center', // To center text as well
        width: '100%',
    },
    dialogContentStyle: {
        width: '80px',
        height: '80px',
        boxShadow: '0px 0px 18px 0px #F3A622',
        background: '#F3A622',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '16px',
        marginTop: "2rem", // Adds spacing below the image
    },
    flex__box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    Congratulationsstyle: {
        fontFamily: 'Josefin Sans',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        color: '#00A7D1',
    },
    countingStyle: {
        fontFamily: 'Chromatica-Regular',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        color: "#252525",
        marginBottom: "3rem",
        marginTop: "1.5rem",
        maxHeight: "190px",
        overflowY: "auto",
        wordBreak: "break-word",
    },

};
// Customizable Area End