import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  
  getCategoryComplete:any
 
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGraphValue=""
  categoryHandleKey=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    this.state = {
    
      getCategoryComplete:[],
    
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
 
  
  async componentDidMount() {
    this.GraphValueHandle();
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
      if (this.getGraphValue === responseData && !responseJson?.errors) {
        this.handleGraphData(responseJson)
      }
    };    
  }
  
   formatDate = (dateString:string) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); 
    const day = String(date.getDate()).padStart(2, "0");        
    const year = date.getFullYear();                    
  
    return `${month}-${day}-${year}`;
  };
  handleGraphData = (responseJson:any) =>{
    const updatedResonse = {
      ...responseJson,
      account_created_at:new Date(responseJson?.account_created_at),
      graph_details:responseJson?.graph_details.map((item:any) => (
        {
          ...item,
          date: this.formatDate(item.created_at)
        }
      ))
    }
  localStorage.setItem("GraphData", JSON.stringify(updatedResonse))
  }
  GraphValueHandle = () => {
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: data
    };
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGraphValue = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.graphEndPoint}?timezone=${timeZone}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

 
}
