import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start

import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  logOutBlockInnerStyle?: React.CSSProperties;
  logOutBlockContainorStyle?: React.CSSProperties;
  logOutBlock?: () => JSX.Element | React.ReactFragment;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dashboardValue: boolean,
  createValue: boolean,
  saferValue: boolean,
  goalsValue: boolean,
  enableField: false,
  selectCheckbox: false,
  selectedButton: "Interviews",
  reminderswitch: false,
  loader: false,
  LoginToken: string | null,
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export const colors = {
  darkBlue: '#122247',
  lightGrey: '#95979E',
  blue: '#0028FF',
  white: '#ffffff',
  lightBlue: '#E8F5FE',
  black: '#000000',
  lightBlue1: '#C6E5FD',
  darkblue1: '#2850C4',
  cream: '#F4DCCF',
  lightCream: '#C2B1A5',
  green: '#57C898',
  placeholderTextColor: '#8F92A1',
  grey: '#A9A9A9',
  lightGreen: '#55C699',
  grey1: '#E8E8E8',
  bg_Header: '#0D2564',
  btn_blue: '#0028FF',

  light_blue: '#C7E5FD',
  dark_blue: '#0D2666',

  light_cream: '#F4DDCE',
  light_orange: '#F08A59',
  light_red: '#ED6669',
  light_grey: '#E8E8E8',
  dark_grey: '#414141',
  light_grey_1: '#e0e0e0',
  extra_light_blue: '#D1D6E2',

  lable: '#8F92A1',

  calender_darkBlue: '#0129FF',
  calender_lightBlue: '#E8F5FE',
  calender_lightSkin: '#F4DDCE',
  calender_black: '#000000',
  calender_white: '#ffffff',
  calender_lightBlue1: '#C6E5FD',
  calender_darkBlue1: '#0B2066',
  calender_darkBlue2: '#203BC4',
  calender_cream: '#F4DDCE',
  calender_lightcream: '#C2B1A5',
  calender_lightGray: '#F5F5F5',
  calender_arrowBackGroundColor: '#E9E8ED',
  calender_arrowColor: '#91949E',
  calender_green: '#60CC99'
};

export const configJSON = require("./config");
// Customizable Area End




export default class HamburgerMenuController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCategoriesListApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.state = {
      enableField: false,
      selectCheckbox: false,
      selectedButton: "Interviews",
      reminderswitch: false,
      loader: false
      // Customizable Area Start
      , dashboardValue: false,
      LoginToken: "",
      createValue: false,
      saferValue: false,
      goalsValue: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area End


  async receive(from: string, message: Message) {



    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && apiRequestCallId === this.getCategoriesListApiCallId) {
        removeStorageData("BackToSaferuseStrategies");
        localStorage.setItem("token","")
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
    }
    // Customizable Area End

  }



  // Customizable Area Start
  async componentDidMount() {
    const answer = localStorage.getItem("pageNavigate");
    const Logintoken = localStorage.getItem("token");
    this.setState({LoginToken: Logintoken})

    removeStorageData("BackToSaferuseStrategies");
    /* istanbul ignore next */
    if (answer && answer !== "") {
      if (answer === 'DashboardInformation') {
        this.setState({
          dashboardValue: true,
          saferValue: false,
          goalsValue: false,
          createValue: false
        });
      } else if (answer === 'SaferuseStrategies') {
        this.setState({
          saferValue: true,
          dashboardValue: false,
          goalsValue: false,
          createValue: false
        });
      } else if (answer === 'GoalsInformation') {
        this.setState({
          goalsValue: true,
          saferValue: false,
          dashboardValue: false,
          createValue: false
        });
      } else if (
        answer === 'Categoriessubcategories' ||
        answer === 'Goals' ||
        answer === 'CustomForm' ||
        answer === 'OtherGoals' ||
        answer === 'SubstanceGoals'
      ) {
        this.setState({
          createValue: true,
          dashboardValue: false,
          goalsValue: false,
          saferValue: false,
        });
      }
    }

  }
  CreateGoal() {
    /* istanbul ignore next */
    localStorage.setItem("NewUseFound", "No")
    this.props.navigation.navigate('Goals')
    removeStorageData("BackToSaferuseStrategies");
    removeStorageData("newData");
    removeStorageData("selectedCardId");
    removeStorageData("selectedSubstance");
    removeStorageData("firstSelectedCardId");
    removeStorageData("firstNewData");
    localStorage.removeItem("valuesSequence");
    localStorage.removeItem("currentValue");
    localStorage.removeItem("selectedTab");
    removeStorageData("selectedSubstance");
  }
  handleClose() {
    const answer = localStorage.getItem("pageNavigate")
    if (answer && answer != "") {
      this.props.navigation.navigate(answer)
      localStorage.removeItem("pageNavigate");
    }

  }
  ReviewGoals() {
    removeStorageData("BackToSaferuseStrategies");
    removeStorageData("weekValue");
    removeStorageData("goalValue");
    this.props.navigation.navigate("GoalsInformation")
  }
  SaferUser() {
    setStorageData("BackToSaferuseStrategies", "SaferUserStrategies");
    removeStorageData("activeTab");
    this.props.navigation.navigate("SaferuseStrategies")
  }
  Dashboard() {
    removeStorageData("BackToSaferuseStrategies");
    this.props.navigation.navigate("DashboardInformation")
  }
  Logout() {
    const token = localStorage.getItem("firebaseToken");
    const Logintoken = localStorage.getItem("token");

    const header = {
      "Content-Type": "application/json",
      token: Logintoken
    };

    const categoryMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesListApiCallId = categoryMessage.messageId;

    categoryMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/remove_device_id"
    );

    categoryMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    categoryMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ device_id: token })
    );

    categoryMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(categoryMessage.id, categoryMessage);
  }


  // Customizable Area End

}
