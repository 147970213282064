import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
export const baseURL = require("../../../framework/src/config.js");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentQuestion: number,
      selectedValue: string,
      questions:any;
      openCongratessafer:boolean
      openQustinary:boolean;
      buttonText:string;
      scores: any, 
      successMsg:any;
      updatedScoresOfAll:any,
      userName:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  deleteBulkUploadCallId: string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  harmReductionScoresApiCallId:string="";
 
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   
      currentQuestion: 0,
      selectedValue: "",
      openQustinary:false,
      buttonText:"Next",
       questions :[
        {
          id:1
,          question: "1. I have been unhappy because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:2,
          question: "2. Because of my drinking/drug use, I have not eaten properly.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:3,
          question: "3. I have failed to do what is expected of me because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:4,
          question: "4. I have felt guilty or ashamed because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:5,
          question: "5. I have taken foolish risks when I have been drinking/using drugs.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:6,
          question: "6. When drinking/using drugs, I have done impulsive things that I regretted later.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:7,
          question: "7. My physical health has been harmed by my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:8,
          question: "8. I have had money problems because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:9,
          question: "9. My physical appearance has been harmed by my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:10,
          question: "10. My family has been hurt by my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:11,
          question: "11. A friendship or close relationship has been damaged by my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:12,
          question: "12. My drinking/drug use has gotten in the way of my growth as a person.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:13,
          question: "13. My drinking/drug use has damaged my social life, popularity, or reputation.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:14,
          question: "14. I have spent too much or lost a lot of money because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:15,
          question: "15. I have had an accident while drinking/using drugs/intoxicated.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        
      ],
      scores: [], 
      successMsg:"",
      openCongratessafer:false,
      updatedScoresOfAll:null,
      userName:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.harmReductionScoresApiCallId) {
          this.postUppersResponse(responseJson);
        }
      }
      
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  const currentQuestion = await getStorageData("currentQuestion");
 const getHandletoggle = localStorage.getItem("handletoggle")
 if (!getHandletoggle) {
      this.setState({ openCongratessafer: true });

}
if(currentQuestion !== null) {
  this.setState({ currentQuestion: Number(currentQuestion) });
}
 
   this.setState({
    userName: localStorage.getItem("username")
  })
  }

  hamburgerMenuass(){
    localStorage.setItem("pageNavigate","Assessment")
    this.props.navigation.navigate("HamburgerMenu")
  }
  goToDashboard(){
   
    this.props.navigation.navigate("DashboardInformation")
  }
  handleNextass = () => {
    setStorageData("currentQuestion", Number(this.state.currentQuestion) + 1);
    if (this.state.currentQuestion < this.state.questions.length - 1) {
      this.setState((prevState) => ({
        currentQuestion: prevState.currentQuestion + 1,
        selectedValue: ""
      }));
    } else {
      this.handleComplete(); 
    }
  };

  handleComplete = () => {
    const score = this.state.scores.reduce((acc:any, curr:any) => acc + curr, 0);
    this.addQueryApi(score)
    removeStorageData("currentQuestion");
    removeStorageData("selectedValues");

   this.setState({openQustinary:true,updatedScoresOfAll:score})
  
  }
  handleRadioChange = async (event:any) => {
    const selectedOption = event.target.value; 
    const questionOptions = this.state.questions[this.state.currentQuestion]?.options;
    const score = questionOptions?.indexOf(selectedOption) + 0;
  
    this.setState({ selectedValue: selectedOption });
    const prevSelectedValues = await getStorageData("selectedValues");
    const updatedScores = prevSelectedValues ? JSON.parse(prevSelectedValues) : [...this.state.scores];
    updatedScores[this.state.currentQuestion] = score; 
    this.setState({ scores: updatedScores });
    setStorageData("selectedValues", JSON.stringify(updatedScores));
  };
  
  handleOpensaferToggle = () => {
    this.setState({ openCongratessafer: true });
  };
  handleOpensqustinary = () => {
    this.setState({ openQustinary: true ,buttonText: 'Complete'});
  };
  handleClosesqustinary  = () => {
    this.setState({ openQustinary: false });
  };
  handleClosesaferToggle  = () => {
    this.setState({ openCongratessafer: false });
  };
 
  handleBack = async () => {
    const selectedValues = await getStorageData("selectedValues");
    this.setState((prevState) => ({
      currentQuestion: prevState.currentQuestion > 0 ? prevState.currentQuestion - 1 : 0,
      selectedValue: prevState.questions[prevState.currentQuestion]?.options?.[JSON.parse(selectedValues)?.[prevState.currentQuestion - 1 || 0]] as string,
    }), () => {
      if (!this.state.selectedValue) {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardInformation");
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
      }
    });
  };
 
addQueryApi = (score:number) => {
  localStorage.removeItem("GraphData")
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
 
  
  const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
   
  const httpBody = {
    
      "harm_reduction_score":
      {
          "score": score,
          "timezone":timeZone
      }
  
  };

  const UppersrequestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.harmReductionScoresApiCallId = UppersrequestMessage.messageId;
  UppersrequestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.harmReductionScoresApiEndPoint
  );

  UppersrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  UppersrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  UppersrequestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );

  runEngine.sendMessage(UppersrequestMessage.id, UppersrequestMessage);

  return true;
};

postUppersResponse=(responseJson:any)=>{
  if(!responseJson.error){
    localStorage.setItem("handletoggle","true")
   this.setState({successMsg:responseJson.message})
 }else{
  localStorage.setItem("handletoggle","true")
  if(responseJson?.error==="You have filled harm reduction questionairs in past 2 weeks."){
    this.setState({successMsg:"You have already filled out the harm reduction questionnaire in the last two weeks."})
  }
  else if(responseJson?.error){
   this.setState({successMsg:responseJson.error})
  }
 }
}

  // Customizable Area End
}