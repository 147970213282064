Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.SubstanceGoalsThisWeek="bx_block_custom_forms/substance_goal_responses/this_week_goals?category="
exports.SubstanceGoalsLastWeek="bx_block_custom_forms/substance_goal_responses/last_week_goals?category="
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.categoryShowPoint="account_block/identify_new_user"
exports.thisWeekEndpoint = "bx_block_custom_forms/other_life_goals/this_week_other_life_goals";
exports.lastWeekEndpoint = "bx_block_custom_forms/other_life_goals/last_week_other_life_goals";
exports.otacheiveEndpoint = "bx_block_custom_forms/other_life_goals";
exports.substanceAcheiveEndpoint = "bx_block_custom_forms/substance_goal_responses"
exports.saferuserstrategiesEndpoint = "bx_block_custom_forms";
exports.lastweekdaferEndpoint = "bx_block_custom_forms";
exports.goalsEndPoint='bx_block_custom_forms/substance_goal_responses/last_three_goals'
exports.achieveStrategiesEndpoint = "bx_block_custom_forms/safer_user_strategies";
exports.goalsTotalEndPoint = "bx_block_custom_forms/substance_goal_responses/count_goals";
exports.saferUserTotalEndPoint = "bx_block_custom_forms/safer_user_strategies/last_three_safer_user_strategies";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAccept1 = "*/*";
exports.dashboarAccept2 = "*/*";
exports.dashboarAccept3 = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.dashboartoken1 = "";
exports.dashboartoken2 = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.Spring ="Spring";
exports.Well ="Well";
exports.WellSpring ="Well Spring";
exports.WellSpring1 ="Spring Well";
exports.Yourcountingdrinksgoal = "Your counting drinks goal";
exports. HeyTomChandler =" Hey, Tom Chandler";
exports.viewMore = "View More";
exports.StillWorking = "Still Working";
exports.AchievedGoal = "Achieved Goal"
exports.labelBodyText = "dashboard Body";
exports.labelBodyText1 = "dashboard Body";
exports.PopupGetApiMethod ="GET"
exports.PopupGetApiMethod1 ="GET"
exports.deleteSaferUserApiEndPoint ="bx_block_custom_forms/safer_user_strategies"
exports.DeletesaferUserAPiMethod ="DELETE"
exports.deleteOtherGoalsApiEndPoint ="bx_block_custom_forms/other_life_goals"
exports.deleteSubstanceGoalsApiEndPoint ="bx_block_custom_forms/substance_goal_responses"
exports.PopupGetApiendPoint = "bx_block_custom_forms/harm_reduction_scores/harm_reduction_question_available";
exports.goalCategoriesEndPoint = "bx_block_custom_forms/substance_goal_responses/categories_list";
exports.stretagyCategoriesEndPoint = "bx_block_custom_forms/safer_user_strategies/categories_list";
exports.data= [
  { month: 'Jan'},
  { month: 'Feb'},
  { month: 'Mar'},
  { month: 'Apr'},
  { month: 'May'},
  { month: 'Jun'},
  { month: 'Jul'},
  { month: 'Aug'},
  { month: 'Sep'},
  { month: 'Oct'},
  { month: 'Nov' },
  { month: 'Dec'},
];
// Customizable Area End